import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import "./App.css";
import Modal from "./Modal";
import MapComponent from "./MapComponent.js"; // Import the MapComponent
import HangarScreen from './HangarScreen';
import ProfileScreen from './ProfileScreen';
import TravelViewer from './TravelViewer';

function App() {
  const [telegramUserId, setTelegramUserId] = useState(null);
  const [playerInfo, setPlayerInfo] = useState(null);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);
  const [showFuelPopup, setShowFuelPopup] = useState(false);
  const [showInitOptions, setShowInitOptions] = useState(false);
  const [showCityMenu, setShowCityMenu] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [showShopMenu, setShowShopMenu] = useState(false);
  const [showHangarMenu, setShowHangarMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showDestinations, setShowDestinations] = useState(false);
  const [showDestinationsButton, setShowDestinationsButton] = useState(true);
  const [showTravelView, setShowTravelView] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [possibleDestinations, setPossibleDestinations] = useState([]);
  const [error, setError] = useState(null);
  const [fuelAmount, setFuelAmount] = useState("");
  const [timer, setTimer] = useState(0);
  const [activeTrip, setActiveTrip] = useState(null);
  const [expectedReward, setExpectedReward] = useState(null);
  const [walletAvailable, setWalletAvailable] = useState(false);
  const [tripDuration, setTripDuration] = useState(0);

  const timerRef = useRef(null);
  const endTimeRef = useRef(null);
  const adControllerRef = useRef(null);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Initialize Telegram WebApp
  useEffect(() => {
    let tg;
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-web-app.js";
    script.async = true;
    script.onload = () => {
      tg = window.Telegram?.WebApp;
      if (!tg) {
        console.error("Telegram WebApp is not available after script load");
        return;
      }
      tg.expand();
      //tg.requestFullscreen();
      tg.ready();
      const id = tg.initDataUnsafe?.user?.id;
      setTelegramUserId(id);  
      tg.disableVerticalSwipes();  
    };
    script.onerror = () => {
      console.error("Failed to load Telegram WebApp SDK");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Load and Initialize AdsGram SDK
  useEffect(() => {
    const scriptAd = document.createElement("script");
    scriptAd.src = "https://sad.adsgram.ai/js/sad.min.js";
    scriptAd.async = true;
    scriptAd.onload = () => {
      if (window.Adsgram) {
        adControllerRef.current = window.Adsgram.init({ blockId: '8986' });
        console.log("AdController initialized:", adControllerRef.current);
      } else {
        console.error("window.Adsgram is undefined after script load");
      }
    };
    scriptAd.onerror = () => {
      console.error("Failed to load AdsGram script");
      alert("Failed to load AdsGram script");
    };
    document.body.appendChild(scriptAd);
    return () => {
      document.body.removeChild(scriptAd);
    };
  }, []);

    // Check if the user is new and handle wallet creation visibility
  const initiateGame = useCallback(async () => {
    try {
      const response = await axios.post("https://steamboat.site:3000/init", {
        playerId: telegramUserId,
      });
      const { createWallet, message } = response.data; // Extract the data
      //alert(`create wallet? ${message}`); // Show the message from the response
      if (createWallet === true)
      {
        setShowInitOptions(true);
        setWalletAvailable(false);
      } else {
        setShowInitOptions(true);
        setWalletAvailable(true);
      }

    } catch (error) {
      setError("Error initiating game");
    }
  }, [telegramUserId]);

  // Create new wallet
  const createWalletClicked = useCallback(async () => {
    try {
      const response = await axios.post("https://steamboat.site:3000/create-wallet", {
        playerId: telegramUserId,
      });
      const { walletAddress, privateKey } = response.data; // Extract the data

      alert(`Wallet created! \n Wallet address: \n ${walletAddress} \n Private key: \n ${privateKey}`);
      setWalletAvailable(true);
    } catch (error) {
      setError("Error sending create wallet request");
    }
  }, [telegramUserId]);

  // Fetch player information from the backend
  const fetchPlayerInfo = useCallback(async () => {
    try {
      const response = await axios.post(`https://steamboat.site:3000/playerinfo`, {
        playerId: telegramUserId,
      });
      setPlayerInfo(response.data.player);
    } catch (error) {
      setError("Error fetching player info");
    }
  }, [telegramUserId]);

  // Fetch possible destinations from the backend
  const fetchDestinations = useCallback(async () => {
    try {
      const response = await axios.post("https://steamboat.site:3000/destinations", {
        playerId: telegramUserId,
      });
      setPossibleDestinations(response.data.possibleDestinations);
    } catch (error) {
      setError("Error fetching destinations");
    }
  }, [telegramUserId]);

  // Start the trip
  const startTrip = async (destination, destName) => {
    try {
      const response = await axios.post("https://steamboat.site:3000/start-trip", {
        playerId: telegramUserId,
        destination,
        destName
      });
      setActiveTrip(response.data.activeTrip);
      setExpectedReward(response.data.reward);
      setTripDuration(response.data.tripTime);
      const endTime = Date.now() + response.data.tripTime * 1000;
      endTimeRef.current = endTime;
      setTimer(response.data.tripTime);
      updateTimer();
      setModalTitle(`Trip to ${response.data.destName} started!`);
      setModalMessage(`Fuel Cost: ${response.data.fuelCost}\nReward: ${response.data.reward}\nTrip Time left: ${formatTime(response.data.tripTime)} `);
      setIsModalOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert(error.response.data.error);
      }
      //await checkActiveTrip();
      setError("Error starting the trip", error.response);
    }
  };

  // Trip Completion Handler
  const completeTrip = useCallback(async () => {     
      const response = await axios.post("https://steamboat.site:3000/complete-trip", {
        playerId: telegramUserId,
      });
      const respCompletedTrip = response.data.completedTrip;
      
      //setCompletedTrip(respCompletedTrip);
      setModalTitle(`You arrived at ${respCompletedTrip.destName}!`);
      setModalMessage(`Reward: ${respCompletedTrip.reward}`);
      setIsModalOpen(true);

      await fetchPlayerInfo();
      await fetchDestinations();
      setShowDestinationsButton(true);
      setActiveTrip(null);
      setExpectedReward(null);
      setTimer(0);
      setShowDestinations(false); 
      setShowTravelView(false);
      setShowMap(true);    
    }, [telegramUserId, fetchPlayerInfo, fetchDestinations]);

  const updateTimer = useCallback(() => {
    // Clear any existing interval
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      const remainingTime = Math.max(0, Math.floor((endTimeRef.current - Date.now()) / 1000));
      setTimer(remainingTime);
      if (remainingTime <= 0) {
        clearInterval(timerRef.current);
        setTimer(0);
        completeTrip();
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerRef.current);
  }, [completeTrip]);

  // Check Active Trip from Backend
  const checkActiveTrip = useCallback(async () => {
    try {
      const response = await axios.post("https://steamboat.site:3000/active-trip", {
        playerId: telegramUserId,
      });
      
      if (response.data.remainingTime > 0) {
        setActiveTrip(response.data.activeTripInfo);
        setExpectedReward(response.data.activeTripInfo.reward);
        const endTime = Date.now() + response.data.remainingTime * 1000;
        endTimeRef.current = endTime;
        setTripDuration(response.data.remainingTime);
        setTimer(response.data.remainingTime);
        updateTimer();
      } else if (response.data.activeTrip === null) {
        setActiveTrip(null);
        setTimer(0);
      }
      
    } catch (error) {
      setError("Error checking active trip", error);
    }
  }, [telegramUserId, updateTimer]);

  const processFuelPurchase = async () => {
    const amount = Number(fuelAmount);

    // Validate input
    if (!amount || isNaN(amount) || amount <= 0) {
      alert("Please enter a valid token amount.");
      return;
    }

    try {
      const response = await axios.post("https://steamboat.site:3000/buy-fuel", {
        playerId: telegramUserId,
        tokenAmount: amount,
        fuelAmount: amount,
      });

      if (response.data.success) {
        alert("Fuel purchased successfully!");
        fetchPlayerInfo(); // Refresh after purchase
        setShowFuelPopup(false);
        setFuelAmount(""); // Reset input field
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      alert("Error purchasing fuel");
    }
  };

  // Handle the click on the "PLAY" button (to go to the game screen)
  const handlePlayClick = () => {
    if (!walletAvailable) {
      createWalletClicked(); // Function to create a wallet
    } else {
      setShowWelcomeScreen(false); // Switch to the main game screen
    }
  };

  const handleBuyFuelClick = () => {
    setShowFuelPopup(true);
  };

  const handleShowDestinationsClick = () => {
    setShowDestinations(true);
    setShowCityMenu(false);
  };

  const handleStartTripClick = (selectedCity) => {
    setShowDestinationsButton(false);
    startTrip(selectedCity.destination, selectedCity.destName);
  };  

  const startShopMenu = () => {
    checkActiveTrip();
    if (activeTrip) {
      alert("Shop disabled while traveling!");
      return;
    }
    setShowShopMenu(true);
    setShowCityMenu(false);
    setShowHangarMenu(false);
    setShowProfileMenu(false);
    setShowMap(false);
    setShowDestinationsButton(false);
    setShowDestinations(false);
  };

  const startCityMenu = (destination) => {
    checkActiveTrip();
    if (activeTrip) {
      alert("City menu disabled while traveling!");
      return;
    }
    setSelectedCity(destination);
    setShowCityMenu(true);
    setShowHangarMenu(false);
    setShowShopMenu(false);
    setShowProfileMenu(false);
    setShowMap(false);
    setShowDestinationsButton(true);
    setShowDestinations(false);
  };

  const startMapMenu = () => {
    setShowHangarMenu(false);
    setShowCityMenu(false);
    setShowShopMenu(false);
    setShowProfileMenu(false);
    setShowMap(true);
    setShowDestinationsButton(true);
    setShowDestinations(false);
  };

  const startHangarMenu = () => {
    checkActiveTrip();
    if (activeTrip) {
      alert("Hangar disabled while traveling!");
      return;
    }
    setShowCityMenu(false);
    setShowHangarMenu(true);
    setShowProfileMenu(false);
    setShowShopMenu(false);
    setShowMap(false);
    setShowDestinations(false);
    setShowDestinationsButton(false);
  };

  const startProfileMenu = () => {
    checkActiveTrip();
    if (activeTrip) {
      alert("Profile disabled while traveling!");
      return;
    }    
    setShowProfileMenu(true);
    setShowCityMenu(false);
    setShowHangarMenu(false);
    setShowShopMenu(false);
    setShowMap(false);
    setShowDestinations(false);
    setShowDestinationsButton(false);
  };

  // Updated showAd function
  const showAd = () => {
    if (adControllerRef.current) {
      adControllerRef.current
        .show()
        .then(() => {
          console.log("Ad watched successfully");
          axios
            .post("https://steamboat.site:3000/reward-user", {
              playerId: telegramUserId,
              reward: 10,
            })
            .then(() => {
              fetchPlayerInfo();
              alert("Ad watched! You earned 10 tokens.");
            })
            .catch((err) => {
              console.error("Failed to reward user:", err);
              alert("Reward failed. Please try again.");
            });
        })
        .catch((error) => {
          console.error("Ad failed:", error);
          if (error.error) {
            alert(`Failed to load ad: ${error.description}`);
          } else {
            alert("Ad could not be displayed. Please try again.");
          }
        });
    } else {
      console.error("AdsGram not initialized");
      alert("Ads are not ready yet. Please wait a moment.");
    }
  };

  function formatTime(seconds) {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}':${secs.toString().padStart(2, '0')}"`;
}

  // Use effect hook to fetch player info, destinations, etc
  useEffect(() => {
    const loadGameState = async () => {
      if (!showWelcomeScreen && telegramUserId) {
        await checkActiveTrip();
        await fetchPlayerInfo();
        await fetchDestinations();
      } else if (showWelcomeScreen && telegramUserId) {
        await initiateGame();
      }
    };
  
    loadGameState();
  }, [
    showWelcomeScreen,
    telegramUserId,
    checkActiveTrip,
    fetchPlayerInfo,
    fetchDestinations,
    initiateGame
  ]);

return (
  <div className="App">
    {/* Welcome Screen */}
    {showWelcomeScreen ? (
      <div className="welcome-screen">
        <img 
          src="/assets/welcome.jpeg"
          alt="Splash" className="splash-image"
        />     

        {showInitOptions && (
          <div className="init-options">
            <button onClick={handlePlayClick}>
              {walletAvailable ? 'PLAY' : 'CREATE WALLET'}
            </button>
          </div>
        )}
      </div>
    ) : (
      <div className="game-screen">

        {/* Map */}
        {playerInfo && showMap && !showShopMenu && !activeTrip && !showDestinations && !showHangarMenu && (
          <div className="map-container">
            <MapComponent x={playerInfo.x} y={playerInfo.y} range={playerInfo.vessel.maxRange * 2}/> {/* Center map on player's position */} 
          </div>
        )}

        {/* Hangar */}
        {playerInfo && !showShopMenu && !activeTrip && !showDestinations && !showMap && showHangarMenu && (
          <div className="hangar-container">
            <HangarScreen playerInfo={playerInfo}/>
          </div>
        )}

        {/* Profile */}
        {playerInfo && !showShopMenu && !activeTrip && !showDestinations && !showMap && showProfileMenu && (
          <div className="profile-container">
            <ProfileScreen playerInfo = {playerInfo}/>
          </div>
        )}

        {/* Error Handling */}
        {error && <div className="error">{error}</div>}

        {/* Player Info Section */}
        {playerInfo && !activeTrip && !showShopMenu && !showHangarMenu && !showProfileMenu && (          
          <div className="player-info">
            <p>💼 Wallet: ..{playerInfo.wallet.walletAddress.slice(-4)}</p>
            <p>⛽️ Fuel: {playerInfo.fuelTank}
              <button onClick={handleBuyFuelClick}>BUY FUEL</button>
            </p>
            <p>💳 Tokens: {playerInfo.tokenBalance}</p>
            <p>💰 $MICKEY: {playerInfo.wallet?.mickey}</p>           
            <h3>📍 Location: {playerInfo.locationName}</h3>
            <p2> {playerInfo.location}</p2>

            {/* Fuel Purchase Popup */}
            {showFuelPopup && (
              <div className="fuel-popup">
                <h2>💰 FUEL PRICE</h2>
                <h3>1 Fuel = 1 Token</h3>
                <p>Available Tokens: {playerInfo.tokenBalance}</p>
                <input
                  type="number"
                  value={fuelAmount}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "" || /^[0-9\b]+$/.test(value)) {
                      setFuelAmount(value);
                    }
                  }}
                  placeholder="Enter token amount"
                />
                <button onClick={processFuelPurchase}>Confirm Purchase</button>
                <button onClick={() => setShowFuelPopup(false)}>Cancel</button>
              </div>
            )}
          </div>
        )}

        {/* City Menu */}
        {!activeTrip && showCityMenu &&!showMap && (
          <div className="city-menu">
            <p> Destination:</p>
            <h2> {selectedCity.destName} </h2>
            <p> Passengers traveling to {selectedCity.destName}: 4</p>
            <p> Cargo available: Yes</p>
            <p> Distance: {Math.round(selectedCity.distance * 6.7)} </p>
            <p> Fuel cost - deliver mail and parcels: {selectedCity.fuelCost}</p>
            <p> Fuel cost with passengers: {selectedCity.fuelCost * 1.5}</p>
            <p> Trip duration: {formatTime(selectedCity.time)}</p>
            <p> Reward: {selectedCity.rewardText}</p>
            <button onClick={() => handleStartTripClick(selectedCity)}> FLY!
            </button>
          </div>        
        )}

        {/* Show Destinations button group */}
        {!activeTrip && showDestinationsButton && (
        <div className="destinations-button-group">
          <div className="destinations-button">
            <button onClick={handleShowDestinationsClick}>Destinations</button>
          </div>
        </div>
        )}

        {/* Traveling & Available Destinations Section */}
        {activeTrip && timer ? (
          <div className="trip-timer">
            {checkActiveTrip}
            
            <div className="timer-dep-group"
              style={{
                left: `20%`,                
              }}>
              <p>Departure</p>
            </div>
          
            {/* Zeppelin Icon for Animating Trip */}
            <div className="zeppelin-traveling"
                        style={{
                          position: 'absolute',
                          top: '20%',
                          left: '0%',
                          transform: 'translate(-50%, -88%)',
                          animationName: 'moveRight',
                          animationDuration: `${tripDuration-2}s`,
                          animationTimingFunction: 'ease-in-out',
                          animationFillMode: 'forwards',
                        }}>
              <img 
                src="/assets/icon_01_256x256.png" 
                alt="Zeppelin Traveling"
                style={{ 
                  /*animation: `moveRight 10s ease-in-out forwards`,*/
                  width: `50px`,
                  height: `50px`,
                  transform: `translate(-50%, -50%)`,
                }}
              />
            </div>
            <h3>Traveling to {activeTrip.destName}</h3>
            <h1>⏳ {formatTime(timer)}</h1>
            <p>🎁 Expected Reward: {expectedReward}</p>

            {/* Travel Menu */}
            <div className="travelMenu">
              <h2> Travel Menu </h2>
              {tripDuration > 10 && (
              <button onClick={() => setShowTravelView(true)} style={{ padding: '10px', marginTop: '20px' }}>
              ENTER TRAVEL VIEW
              </button>
            )}
              <button onClick={showAd}>WATCH AD</button>
            </div>
          </div>
        ) : showDestinations && (
          <div className="destination-group">            
            {/* Destination List */}
            {!activeTrip && !showCityMenu && (
              <div className="destination-selection">
                <ul>
                  {possibleDestinations.length > 0 ? (
                    possibleDestinations.map((destination, index) => (
                      <li key={index}>
                        <h2> {destination.destName} </h2> {destination.destination}
                        - Distance {Math.ceil(destination.distance)} 
                        - Fuel cost: {destination.fuelCost} 
                        - Duration: {formatTime(destination.time)} 
                        - Reward: {destination.rewardText}
                        <button onClick={() => startCityMenu(destination)}>
                          View
                        </button>
                      </li>
                    ))
                  ) : (
                    <li>No destinations available</li>
                  )}
                </ul>
              </div>
            )}

          </div>                  
        )}

        {/* Bottom Buttons - Only visible when welcome screen is deactivated */}
        <div className="bottom-buttons">
          <button onClick={startHangarMenu}>Hangar</button> {/* NEW LINE */}
          <button onClick={startMapMenu}>Map</button>
          <button onClick={startShopMenu}>Shop</button>
          <button onClick={startProfileMenu}>Profile</button>
          <button onClick={() => console.log('Help Clicked')}>Help</button>
        </div>

        {/* Modal Component */}
        <Modal
          isOpen={isModalOpen}
          title={modalTitle}
          message={modalMessage}
          onClose={closeModal}
        />

        {/* Shop Menu */}
        {!activeTrip && showShopMenu && (
          <div className="shop-menu">
            <h2> SHOP </h2>
          </div>        
        )}

        {/* Travel Viewer, webGL */}
        {showTravelView && (
          <div style={{ display: showTravelView ? 'block' : 'none' }}>
            <TravelViewer onExit={() => setShowTravelView(false)} />
          </div>
        )}

      </div>
    )}
  </div>  
);
}

export default App;