import React, { useState, useEffect, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./MapComponent.css";

const MapComponent = ({ x, y, range }) => {
  const mapSizeX = 4096;
  const mapSizeY = 2731;
  const transformRef = useRef(null);
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [debugInfo, setDebugInfo] = useState({
    input: { x, y },
    viewport: { width: window.innerWidth, height: window.innerHeight },
    panning: { x: 0, y: 0, scale: 1 },
  });

  // Update viewport size on resize
  useEffect(() => {
    const handleResize = () => {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Center the map on (x, y) with scale=1
  const centerX = -(x - viewportSize.width / 2);
  const centerY = -(y - viewportSize.height / 2);

  // Set initial transform and update debug info
  useEffect(() => {
    if (transformRef.current && viewportSize.width > 0 && viewportSize.height > 0) {
      transformRef.current.setTransform(centerX, centerY, 1);
      setDebugInfo({
        input: { x, y },
        viewport: viewportSize,
        panning: { x: centerX, y: centerY, scale: 1 },
      });
    }
  }, [centerX, centerY, viewportSize, x, y]);

  // Log initialization
  const onInit = ({ positionX, positionY, scale }) => {
    console.log("Initialized at:", { positionX, positionY, scale });
    setDebugInfo((prev) => ({
      ...prev,
      panning: { x: positionX, y: positionY, scale },
    }));
  };

  const recenterMap = () => {
    const centerX = -(x - viewportSize.width / 2);
    const centerY = -(y - viewportSize.height / 2);
    transformRef.current.setTransform(centerX, centerY, 1);
  }

  // Update debug info on panning
  const onPanning = (e) => {
    const { positionX, positionY, scale } = e.state; // Access state directly
    setDebugInfo((prev) => ({
      ...prev,
      panning: { x: positionX, y: positionY, scale },
    }));
  };

  // Prevent jumping on touch start (optional)
  const onPanningStart = () => {
    console.log("Panning started");
  };

  return (
    <div className="map-container">
        <TransformWrapper
            ref={transformRef}
            initialScale={1}
            initialPositionX={centerX}  // Set initial horizontal position
            initialPositionY={centerY}  // Set initial vertical position
            minScale={0.5}
            maxScale={3}
            limitToBounds={false}
            wheel={{ step: 0.1 }}
            pinch={{ step: 5 }}
            panning={{ velocityDisabled: false }}
            onInit={onInit}
            onPanning={onPanning}
            onPanningStart={onPanningStart}
        >
            <TransformComponent>
            <div
                style={{
                width: `${mapSizeX}px`,
                height: `${mapSizeY}px`,
                position: "relative",
                background: "gray",
                }}
            >
                <img
                    src="/assets/atlas_map_capitals_b_8192x5461.png"
                    alt="Game Map"
                    style={{
                        width: `${mapSizeX}px`,
                        height: `${mapSizeY}px`,
                        objectFit: "contain",
                        userSelect: "none",
                    }}
                />
                {/* Player Icon (positioned based on x, y) */}
                <div className="player-icon" style={{ 
                    left: `${x}px`, 
                    top: `${y}px`,
                    transform: `translate(-50%, -88%)`
                    }}>
                    <img src="/assets/icon_01_256x256.png" 
                    alt="Player Pin"
                    style={{ 
                        width:`50px`,
                        height: `50px`}}
                    />
                </div>

                {/* Player Range Indicator (circular range around player) */}
                <div className="range-indicator" style={{ 
                  left: `${x}px`, 
                  top: `${y}px`, 
                  transform: `translate(-50%, -50%)`,
                  width: `${range}px`, /* Range diameter, adjust as needed */
                  height: `${range}px` 
                }}>                  
                </div>

            </div> 
            </TransformComponent>
        </TransformWrapper>    

        {/* Debug Overlay */}
        <div className="debug-overlay">
        {showDebugInfo && (
            <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
        )}
        </div>        

        {/* Recenter Map */}
        <div className="recenter-button">
            <button onClick={recenterMap}>Recenter</button>
        </div>
    </div>
  );
};

export default MapComponent;
