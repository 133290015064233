import "./ProfileScreen.css";

const ProfileScreen = ({ playerInfo }) => {
  return (
    <div>
      {/* Render stuff based on playerId */}
      <h2>Profile for user: {playerInfo.telegramId}</h2>
    </div>
  );
};

export default ProfileScreen;


