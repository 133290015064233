// HangarScreen.js
import React, { useState, Suspense } from 'react';

const ZeppelinViewer = React.lazy(() => import('./ZeppelinViewer'));

const HangarScreen = ({playerInfo}) => {
  const [showViewer, setShowViewer] = useState(false);

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Hangar</h2>
      <p>Engine Level: {playerInfo.vessel.engineLevel}</p>
      <p>Vessel Type: {playerInfo.vessel.boatLevel}</p>
      <p>Passenger Level: {playerInfo.vessel.passengerLevel}</p>
      <p>Cargo Level: {playerInfo.vessel.cargoLevel}</p>
      <p>Max Range: {playerInfo.vessel.maxRange} </p>


      <button onClick={() => setShowViewer(true)}>Viewer</button>

      {showViewer && (
        <div
          style={{
            position: 'fixed',
            top: 0, left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: '#000000dd',
            zIndex: 9999,
          }}
        >
          <button
            onClick={() => setShowViewer(false)}
            style={{ position: 'absolute', top: 20, right: 20, zIndex: 10000 }}
          >
            Close
          </button>

          <Suspense fallback={<div style={{ color: 'white' }}>Loading Zeppelin...</div>}>
            <ZeppelinViewer />
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default HangarScreen;