// ZeppelinModel.js
import React, { useEffect, useRef } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const ZeppelinModel = ({ scene, onLoad }) => {
  const modelRef = useRef();

  useEffect(() => {
    // Skip if scene already contains the zeppelin
    const alreadyLoaded = scene.children.some(obj => obj.name === 'zeppelin');
    if (alreadyLoaded) {
      console.log("Zeppelin already in scene, skipping load");
      return;
    }

    const loader = new GLTFLoader();
    loader.load(
      '/assets/zeppelin3.gltf',
      (gltf) => {
        const model = gltf.scene;
        model.name = "zeppelin"; // So we can detect it later
        model.scale.set(10, 10, 10);
        model.position.set(0, 0, 0);
        scene.add(model);
        modelRef.current = model;
        if (onLoad) onLoad(model);
        console.log("Zeppelin model loaded and added to scene");
      },
      undefined,
      (error) => {
        console.error('Error loading GLTF:', error);
      }
    );

    // NO cleanup — we want it to persist
  }, [scene, onLoad]);

  return null;
};

export default ZeppelinModel;
