// TravelViewer.js
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import ZeppelinModel from './ZeppelinModel';

const TravelViewer = ({ onExit }) => {
  const mountRef = useRef(null);
  const rendererRef = useRef(null);
  const zeppelinRef = useRef(null);
  const [sceneInstance] = useState(new THREE.Scene());

  useEffect(() => {
    const scene = sceneInstance;
    scene.background = new THREE.Color(0xbfd1e5);

    const camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(100, 10, 100);
    camera.lookAt(0, 0, 0);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = false;
    controls.enabled = true;

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(10, 20, 10);
    scene.add(directionalLight);

    const axesHelper = new THREE.AxesHelper(5);
    scene.add(axesHelper);
        
    const gridHelper = new THREE.GridHelper(20, 20);
    scene.add(gridHelper);
    
    const clock = new THREE.Clock();
    const animate = () => {
    const delta = clock.getDelta();

    if (zeppelinRef.current) {
      zeppelinRef.current.position.x += delta * 0.5;
    }

    controls.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
    };
    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (mountRef.current && rendererRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
    };
  }, [sceneInstance]);

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <button
        onClick={onExit}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 10,
          padding: '10px 20px',
          backgroundColor: '#000',
          color: '#fff',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        Exit Travel View
      </button>
      <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
      <ZeppelinModel scene={sceneInstance} onLoad={(model) => (zeppelinRef.current = model)} />
    </div>
  );
};

export default TravelViewer;
